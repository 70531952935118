<template lang="pug">
header.l-header
  .header-content
    dropdown-menu(:withDropdownCloser="true" :isOpen="burgerMenu" @opened="burgerMenu = true" @closed="burgerMenu = false")
      .header-burger(slot="trigger")
        .header-burger-button.external_link#header_burger_btn(:class="{'is-opened' : burgerMenu }")
          span
          span
          span
      .header-burger-menu(slot="body")
        .header-burger-menu-content
          .header-burger-menu-content-left
            .campaigns
              .campaign
                .campaign-thumb
                  a.external_link#header_burger_campaign(href="https://career.vook.vc/" dropdown-closer)
                    picture
                      source(media="(max-width: 640px)" :srcset="`${convertAssetImage(require('~/assets/images/header/thumb-career.jpg'), 342, 342, 'webp')}, ${convertAssetImage(require('~/assets/images/header/thumb-career.jpg'), 684, 684, 'webp')} 2x`" type="image/webp")
                      source(media="(max-width: 640px)" :srcset="`${convertAssetImage(require('~/assets/images/header/thumb-career.jpg'), 342, 342, 'jpeg')}, ${convertAssetImage(require('~/assets/images/header/thumb-career.jpg'), 684, 684, 'jpeg')} 2x`")
                      source(:srcset="`${convertAssetImage(require('~/assets/images/header/thumb-career.jpg'), 270, 270, 'webp')}, ${convertAssetImage(require('~/assets/images/header/thumb-career.jpg'), 540, 540, 'webp')} 2x`" type="image/webp")
                      source(:srcset="`${convertAssetImage(require('~/assets/images/header/thumb-career.jpg'), 270, 270, 'jpeg')}, ${convertAssetImage(require('~/assets/images/header/thumb-career.jpg'), 540, 540, 'jpeg')} 2x`")
                      img(:src="convertAssetImage(require('~/assets/images/header/thumb-career.jpg'), 270, 270, 'jpeg')" width="280" height="147" alt="抽選で50名様にAmazonギフトカードをプレゼント！映像制作に関するアンケートを実施中")
                .campaign-txt
                  a.campaign-txt-ttl.external_link#header_burger_campaign(href="https://career.vook.vc/" dropdown-closer)
                    | 【登録無料】映像プロフェッショナル人材のためのキャリア支援サービス『Vookキャリア』
                  a.external_link#header_burger_campaign(href="https://career.vook.vc/" dropdown-closer)
                    | Learn More
              //- MEMO: キャンペーン枠、2枠まで掲載可能なため、記述は残しておく。
              //- .campaign
                .campaign-thumb
                  nuxt-link.external_link#header_burger_campaign(to="/vgt2024" dropdown-closer)
                    picture
                      source(media="(max-width: 640px)" :srcset="`${convertAssetImage(require('~/assets/images/header/campaign.png'), 342, 342, 'webp')}, ${convertAssetImage(require('~/assets/images/header/campaign.png'), 684, 684, 'webp')} 2x`" type="image/webp")
                      source(media="(max-width: 640px)" :srcset="`${convertAssetImage(require('~/assets/images/header/campaign.png'), 342, 342, 'jpeg')}, ${convertAssetImage(require('~/assets/images/header/campaign.png'), 684, 684, 'jpeg')} 2x`")
                      source(:srcset="`${convertAssetImage(require('~/assets/images/header/campaign.png'), 270, 270, 'webp')}, ${convertAssetImage(require('~/assets/images/header/campaign.png'), 540, 540, 'webp')} 2x`" type="image/webp")
                      source(:srcset="`${convertAssetImage(require('~/assets/images/header/campaign.png'), 270, 270, 'jpeg')}, ${convertAssetImage(require('~/assets/images/header/campaign.png'), 540, 540, 'jpeg')} 2x`")
                      img(:src="convertAssetImage(require('~/assets/images/header/campaign.png'), 270, 270, 'jpeg')" width="280" height="147" alt="VIDEOGRAPHERS TOKYO 2024")
                .campaign-txt
                  nuxt-link.campaign-txt-ttl.external_linkl#header_burger_campaign(to="/vgt2024" dropdown-closer)
                    | VIDEOGRAPHERS TOKYO 2024
                    br
                    | 映像クリエイター向けカンファレンスイベント 2024年7月9日(火)・10日(水)開催！@TOKYO NODE（虎ノ門）
                  nuxt-link.external_link#header_burger_campaign(to="/vgt2024" dropdown-closer)
                    | Learn More

            .search-wrap
              .search
                search-input(@isClose="isClose")
              nuxt-link.guide.external_link#header_burger_guide(to="/pages/guide" dropdown-closer)
                icon-beginner-svg
                | 初めての方

            .contents
              ul
                li
                  nuxt-link.external_link#header_burger_note(to="/contents" dropdown-closer)
                    | 記事
                li
                  nuxt-link.external_link#header_burger_movie(to="/contents?type=movie" dropdown-closer)
                    | 映像
                li
                  nuxt-link.external_link#header_burger_event(to="/events" dropdown-closer)
                    | ウェビナー・イベント
                    .notice {{ event_count }}
                li
                  nuxt-link.external_link#header_burger_campaign(to="/campaign" dropdown-closer)
                    | キャンペーン
                li
                  nuxt-link.external_link#header_burger_list(to="/list" dropdown-closer)
                    | 特集
                li
                  nuxt-link.external_link#header_burger_serializations(to="/serializations" dropdown-closer)
                    | 連載
            .theme
              ul
                li
                  nuxt-link.external_link#header_burger_theme_trend(to="/themes/1" dropdown-closer)
                    themeTrend
                    .txt
                      strong TREND
                      span トレンド
                li
                  nuxt-link.external_link#header_burger_theme_creativity(to="/themes/2" dropdown-closer)
                    themeCreativity
                    .txt
                      strong CREATIVITY
                      span クリエイティビティ
                li
                  nuxt-link.external_link#header_burger_theme_business(to="/themes/3" dropdown-closer)
                    themeBusiness
                    .txt
                      strong BUSINESS
                      span ビジネス
                li
                  nuxt-link.external_link#header_burger_theme_career(to="/themes/4" dropdown-closer)
                    themeCareer
                    .txt
                      strong CAREER
                      span キャリア
                li
                  nuxt-link.external_link#header_burger_theme_skill(to="/themes/5" dropdown-closer)
                    themeSkill
                    .txt
                      strong SKILL
                      span 映像制作スキル

            //- ログインユーザーに表示
            .post(v-if="$auth.loggedIn")
              .post-item
                .post-item-ttl
                  | 記事を投稿
                ul
                  li
                    nuxt-link.external_link#header_burger_post_note_post(to="/n/new" dropdown-closer)
                      icon-post-svg.is-fill
                      | 投稿する
                  li
                    nuxt-link.external_link#header_burger_post_note_draft(:to="`/${$auth.user.username || $auth.user.id}/draft`" dropdown-closer)
                      icon-edit-svg.is-stroke
                      | 下書き一覧
              .post-item
                .post-item-ttl
                  | ポートフォリオを投稿
                ul
                  li
                    nuxt-link.external_link#header_burger_post_portfolio_post(to="/w/new" dropdown-closer)
                      icon-post-svg.is-fill
                      | 投稿する
                  li
                    nuxt-link.external_link#header_burger_post_portfolio_draft(:to="`/${$auth.user.username || $auth.user.id}/portfolio_draft`" dropdown-closer)
                      icon-edit-svg.is-stroke
                      | 下書き一覧

          .header-burger-menu-content-right
            .tool
              .tool-content
                .tool-ttl ツール
                ul
                  li
                    nuxt-link.tool-link.external_link#header_burger_terms(to="/terms" dropdown-closer)
                      .tool-link-thumb
                        picture
                          source(:srcset="`${convertAssetImage(require('~/assets/images/header/thumb-terms.jpg'), 280, 147, 'webp')}, ${convertAssetImage(require('~/assets/images/header/thumb-terms.jpg'), 560, 294, 'webp')} 2x`" type="image/webp")
                          source(:srcset="`${convertAssetImage(require('~/assets/images/header/thumb-terms.jpg'), 280, 147, 'jpeg')}, ${convertAssetImage(require('~/assets/images/header/thumb-terms.jpg'), 560, 294, 'jpeg')} 2x`")
                          img(:src="convertAssetImage(require('~/assets/images/header/thumb-terms.jpg'), 280, 147, 'jpeg')" width="280" height="147" loading="lazy" alt="映像制作 用語辞典")
                      .tool-link-txt
                        strong 映像制作 用語辞典
                        span 単語の意味を詳しく解説
                  //- リニューアル時点で非公開のためコメントアウト
                  //- li
                    nuxt-link.tool-link.external_link#header_burger_research(to="#" dropdown-closer)
                      .tool-link-thumb
                        picture
                          source(:srcset="`${convertAssetImage(require('~/assets/images/header/thumb-research.jpg'), 280, 147, 'webp')}, ${convertAssetImage(require('~/assets/images/header/thumb-research.jpg'), 560, 294, 'webp')} 2x`" type="image/webp")
                          source(:srcset="`${convertAssetImage(require('~/assets/images/header/thumb-research.jpg'), 280, 147, 'jpeg')}, ${convertAssetImage(require('~/assets/images/header/thumb-research.jpg'), 560, 294, 'jpeg')} 2x`")
                          img(:src="convertAssetImage(require('~/assets/images/header/thumb-research.jpg'), 280, 147, 'jpeg')" width="280" height="147" loading="lazy" alt="リサーチハンドブック")
                      .tool-link-txt
                        strong リサーチハンドブック
                        span 撮影準備から素材探しまで
                  li
                    nuxt-link.tool-link.external_link#header_burger_tutorial(to="/packages" dropdown-closer)
                      .tool-link-thumb
                        picture
                          source(:srcset="`${convertAssetImage(require('~/assets/images/header/thumb-tutorial.jpg'), 280, 147, 'webp')}, ${convertAssetImage(require('~/assets/images/header/thumb-tutorial.jpg'), 560, 294, 'webp')} 2x`" type="image/webp")
                          source(:srcset="`${convertAssetImage(require('~/assets/images/header/thumb-tutorial.jpg'), 280, 147, 'jpeg')}, ${convertAssetImage(require('~/assets/images/header/thumb-tutorial.jpg'), 560, 294, 'jpeg')} 2x`")
                          img(:src="convertAssetImage(require('~/assets/images/header/thumb-tutorial.jpg'), 280, 147, 'jpeg')" width="280" height="147" loading="lazy" alt="チュートリアル")
                      .tool-link-txt
                        strong チュートリアル
                        span 動画で基礎から学べる
              .tool-content
                .tool-ttl サービス
                ul
                  li
                    a.tool-link.external_link#header_burger_school(:href="$urls.school" target="_blank")
                      .tool-link-thumb
                        picture
                          source(:srcset="`${convertAssetImage(require('~/assets/images/header/thumb-school.jpg'), 280, 147, 'webp')}, ${convertAssetImage(require('~/assets/images/header/thumb-school.jpg'), 560, 294, 'webp')} 2x`" type="image/webp")
                          source(:srcset="`${convertAssetImage(require('~/assets/images/header/thumb-school.jpg'), 280, 147, 'jpeg')}, ${convertAssetImage(require('~/assets/images/header/thumb-school.jpg'), 560, 294, 'jpeg')} 2x`")
                          img(:src="convertAssetImage(require('~/assets/images/header/thumb-school.jpg'), 280, 147, 'jpeg')" width="280" height="147" loading="lazy" alt="Vook school")
                      .tool-link-txt
                        logo-school-svg(width="92.41" height="13")
                        span 映像を学べるスクール
                  li
                    a.tool-link.external_link#header_burger_career(:href="$urls.career" target="_blank")
                      .tool-link-thumb
                        picture
                          source(:srcset="`${convertAssetImage(require('~/assets/images/header/thumb-career.jpg'), 280, 147, 'webp')}, ${convertAssetImage(require('~/assets/images/header/thumb-career.jpg'), 560, 294, 'webp')} 2x`" type="image/webp")
                          source(:srcset="`${convertAssetImage(require('~/assets/images/header/thumb-career.jpg'), 280, 147, 'jpeg')}, ${convertAssetImage(require('~/assets/images/header/thumb-career.jpg'), 560, 294, 'jpeg')} 2x`")
                          img(:src="convertAssetImage(require('~/assets/images/header/thumb-career.jpg'), 280, 147, 'jpeg')" width="280" height="147" loading="lazy" alt="Vookキャリア")
                      .tool-link-txt
                        logo-career-svg(width="90" height="17")
                        span 映像に特化した求人サイト

            .sns
              ul
                li
                  a.external_link#header_burger_sns_line(href="https://lin.ee/Jg59z11" target="_blank")
                    img(v-lazy="require('~/assets/images/common/line.png')" width="30" height="30")
                li
                  a.external_link#header_burger_sns_tiktok(href="https://www.tiktok.com/@vook_media" target="_blank")
                    img(v-lazy="require('~/assets/images/common/tiktok-w.png')" width="30" height="30")
                li
                  a.external_link#header_burger_sns_instagram(href="https://www.instagram.com/vook_magazine/" target="_blank")
                    img(v-lazy="require('~/assets/images/common/instagram.png')" width="30" height="30")
                li
                  a.external_link#header_burger_sns_twitter(href="https://twitter.com/Vookjp" target="_blank")
                    sns-twitter-svg
                li
                  a.external_link#header_burger_sns_youtube(href="https://www.youtube.com/channel/UCwQ0VT8WWOT5Nvcg5Q43zxA" target="_blank")
                    img(v-lazy="require('~/assets/images/common/youtube.png')" width="30" height="30")
                li
                  a.external_link#header_burger_sns_facebook(href="https://www.facebook.com/VookJp/" target="_blank")
                    img(v-lazy="require('~/assets/images/common/facebook.png')" width="30" height="30")

    .header-logo
      nuxt-link.external_link#header_logo(to="/")
        icon-logo-vook-svg
    .header-theme
      ul
        li
          nuxt-link.external_link#header_nav_contents(to="/contents") 記事
        li
          nuxt-link.external_link#header_nav_movie(to="/contents?type=movie") 映像
        li
          nuxt-link.external_link#header_nav_events(to="/events") イベント
        li
          nuxt-link.external_link#header_nav_serializations(to="/serializations") 連載
        li
          a.external_link#header_nav_career(:href="$urls.career") 求人情報
    .header-premium(v-if="$auth.loggedIn && $auth.user.status === 'free'")
      nuxt-link.external_link#header_premium(to="/setting/billing")
        | Premiumに登録

    ul.header-icon
      li.pc(v-if="!$auth.loggedIn")
        nuxt-link.header-icon-button.external_link#header_icon_guide(to="/pages/guide")
          .header-icon-button-inner
            icon-beginner-svg
            .header-icon-button-inner-txt 初めての方

      li.pc(v-if="$auth.loggedIn")
        dropdown-menu(direction="right" :withDropdownCloser="true")
          .header-icon-button.external_link#header_icon_post(slot="trigger")
            .header-icon-button-inner.header-icon-button-post
              icon-headerpost-svg
              .header-icon-button-inner-txt 投稿する
          .header-icon-menu-post(slot="body")
            .header-post
              .header-post-article
                .ttl
                  | 記事を投稿
                ul
                  li
                    nuxt-link.external_link#header_icon_post_note_post(to="/n/new" dropdown-closer)
                      icon-post-svg.is-fill
                      | 投稿する
                  li
                    nuxt-link.external_link#header_icon_post_note_draft(:to="`/${$auth.user.username || $auth.user.id}/draft`" dropdown-closer)
                      icon-edit-svg.is-stroke
                      | 下書き一覧
              .header-post-portfolio
                .ttl
                  | ポートフォリオを投稿
                ul
                  li
                    nuxt-link.external_link#header_icon_post_portfolio_post(to="/w/new" dropdown-closer)
                      icon-post-svg.is-fill
                      | 投稿する
                  li
                    nuxt-link.external_link#header_icon_post_portfolio_draft(:to="`/${$auth.user.username || $auth.user.id}/portfolio_draft`" dropdown-closer)
                      icon-edit-svg.is-stroke
                      | 下書き一覧

      li(v-if="$auth.loggedIn")
        dropdown-menu(direction="right" :withDropdownCloser="true")
          .header-icon-button.external_link#header_icon_mypage(slot="trigger")
            .header-icon-button-inner
              .header-icon-button-inner-img
                .notice(v-if="$auth.user.unread_notifications_count > 0")
                  | {{ $auth.user.unread_notifications_count }}
                img(:src="profileImageURL()" width="54" height="54" loading="lazy")
              .header-icon-button-inner-txt マイページ
          .header-icon-menu(slot="body")
            .profile
              .profile-name
                .name
                  template(v-if="$auth.user.nickname")
                    | {{ $auth.user.nickname }}
                  template(v-else-if="$auth.user.username")
                    | {{ $auth.user.username }}
                  template(v-else)
                    | ユーザID未登録
                .status(v-if="$auth.user.status !== 'free'")
                  | Premium
                  template(v-if="$auth.user.status === 'trial'")
                    | 体験中
              .profile-link
                nuxt-link.external_link#header_icon_mypage_username(:to="`/${$auth.user.username || $auth.user.id}`" dropdown-closer)
                  | プロフィールを見る
                nuxt-link.external_link#header_icon_mypage_profile(to="/mypage/profile" dropdown-closer)
                  | プロフィールを編集
            .menu
              ul
                li
                  nuxt-link.external_link#header_icon_mypage_notifications(to="/mypage/notifications" dropdown-closer)
                    icon-notice-svg
                    | 通知
                    .notice(v-if="$auth.user.unread_notifications_count > 0" dropdown-closer)
                      | {{ $auth.user.unread_notifications_count }}
              ul
                li
                  nuxt-link.external_link#header_icon_mypage_account(to="/mypage/account" dropdown-closer)
                    icon-setting-svg
                    | アカウント設定
                li.sp
                  nuxt-link.external_link#header_icon_mypage_profile(to="/mypage/profile" dropdown-closer)
                    icon-simpleuser-svg
                    | 公開用プロフィールの編集
                li.sp
                  nuxt-link.external_link#header_icon_mypage_personal(to="/mypage/personal" dropdown-closer)
                    icon-lock-svg
                    | 個人情報設定
                li.sp
                  nuxt-link.external_link#header_icon_mypage_payment(to="/mypage/payment_info" dropdown-closer)
                    icon-pay-svg
                    | お支払い情報
                li.sp
                  nuxt-link.external_link#header_icon_mypage_charge(to="/mypage/charge_logs" dropdown-closer)
                    icon-receipt-svg
                    | 決済履歴
              ul
                li
                  nuxt-link.external_link#header_icon_mypage_event(to="/mypage/event_entry_histories" dropdown-closer)
                    icon-calendar-svg
                    | 参加イベント
                li
                  nuxt-link.external_link#header_icon_mypage_clips(to="/mypage/clips" dropdown-closer)
                    icon-clip-svg
                    | クリップ
                li
                  nuxt-link.external_link#header_icon_mypage_histories(to="/mypage/package_watch_histories" dropdown-closer)
                    icon-history-svg
                    | 閲覧履歴
            .service
              ul
                li(v-if="['in_school', 'suspension', 'unpaid'].includes($auth.user.school_status)")
                  nuxt-link.mypage-btn.is-school.external_link#header_icon_mypage_school(to="/mypage/school" dropdown-closer)
                    | Vook school マイページ
                li(v-if="$auth.user.status === 'organized_school'")
                  a.mypage-btn.is-school.external_link#header_icon_mypage_online_courses(:href="`${$urls.school}/online_courses`" dropdown-closer)
                    | オンライントレーニング
                li(v-if="$auth.user.school_profile && ['vgo_entry', 'vgo_skill_up'].includes($auth.user.school_profile.plan)")
                  a.mypage-btn.is-school.external_link#header_icon_mypage_online_courses(:href="`${$urls.school}/online_courses/consumer`" dropdown-closer)
                    | カリキュラム一覧
                li(v-if="$auth.user.school_camp_ids.includes(2) && vookMasterStudioDirectorShow")
                  a.mypage-btn.is-school.external_link#header_icon_mypage_studio_director(:href="`${$urls.school}/camps/2`" dropdown-closer)
                    | Vook Master Studio
                    br
                    | ディレクタークラス
                li(v-if="$auth.user.school_camp_ids.includes(3) && vookMasterStudioColoristShow")
                  a.mypage-btn.is-school.external_link#header_icon_mypage_studio_colorist(:href="`${$urls.school}/camps/3`" dropdown-closer)
                    | Vook Master Studio
                    br
                    | カラリストクラス
                li(v-if="isCareerStatus")
                  a.mypage-btn.is-career.external_link#header_icon_mypage_career(:href="`${$urls.career}/mypage`" dropdown-closer)
                    | Vookキャリア マイページ
            .logout
              nuxt-link.logout.external_link#header_icon_mypage_logout(to="/users/sign_out" dropdown-closer)
                | ログアウト

      //- ゲストにのみ表示
      li(v-else)
        dropdown-menu(direction="right" :withDropdownCloser="true")
          .header-icon-button.external_link#header_icon_login(slot="trigger")
            .header-icon-button-inner
              icon-user-svg
              .header-icon-button-inner-txt ログイン
          .header-icon-menu(slot="body")
            .header-icon-menu-mypage
              .login
                .login-member
                  .ttl Vookアカウントをお持ちの方
                  .btn
                    nuxt-link.external_link#header_icon_login_login(to="/users/sign_in" dropdown-closer)
                      | ログイン
                .login-guset
                  .ttl
                    | Vookアカウントを
                    br.pc
                    | お持ちでない方
                  .btn
                    nuxt-link.theme-premium.external_link#header_icon_login_premium(to="/setting/billing" dropdown-closer)
                      | 有料 新規登録
                      span
                        | ウェビナーや限定記事を読み放題！
                  .btn
                    nuxt-link.theme-vook.external_link#header_icon_login_signup(to="/users/sign_up" dropdown-closer)
                      | 無料 新規登録
                      span
                        | 無料のチュートリアルが見れる！
                  p
                    | Vookについて詳しくは
                    span
                      nuxt-link.external_link#header_icon_login_guide(to="/pages/guide" dropdown-closer) こちら

</template>
<script>
import DropdownMenu from 'v-dropdown-menu'
import SearchInput from './search/Input'
import { beforeIt } from '~/libs/dayjs'
import { convertAssetImage } from '~/libs/imagepix'
import IconLogoVookSvg from '~/assets/images/svg/logo_vook.svg?inline'
import IconBeginnerSvg from '~/assets/images/svg/icon_beginner.svg?inline'
import IconHeaderpostSvg from '~/assets/images/svg/icon_header-post.svg?inline'
import IconUserSvg from '~/assets/images/svg/icon_mypage.svg?inline'
import IconPostSvg from '~/assets/images/header/icon-post.svg?inline'
import IconEditSvg from '~/assets/images/svg/icon_edit.svg?inline'
import LogoSchoolSvg from '~/assets/images/common/logo-school.svg?inline'
import LogoCareerSvg from '~/assets/images/common/logo-career.svg?inline'
import IconLockSvg from '~/assets/images/svg/icon_lock.svg?inline'
import IconSettingSvg from '~/assets/images/svg/icon_setting.svg?inline'
import IconSimpleuserSvg from '~/assets/images/svg/icon_user.svg?inline'
import IconNoticeSvg from '~/assets/images/svg/icon_notice.svg?inline'
import IconClipSvg from '~/assets/images/svg/icon_clip.svg?inline'
import IconCalendarSvg from '~/assets/images/svg/icon_calendar.svg?inline'
import IconPaySvg from '~/assets/images/svg/icon_pay.svg?inline'
import IconReceiptSvg from '~/assets/images/svg/icon_receipt.svg?inline'
import IconHistorySvg from '~/assets/images/svg/icon_history.svg?inline'
import SnsTwitterSvg from '~/assets/images/svg/sns_twitter.svg?inline'
import themeTrend from '~/assets/images/svg/theme_trend.svg?inline'
import themeCreativity from '~/assets/images/svg/theme_creativity.svg?inline'
import themeBusiness from '~/assets/images/svg/theme_business.svg?inline'
import themeCareer from '~/assets/images/svg/theme_career.svg?inline'
import themeSkill from '~/assets/images/svg/theme_skill.svg?inline'

export default {
  components: {
    DropdownMenu,
    SearchInput,
    IconLogoVookSvg,
    IconBeginnerSvg,
    IconHeaderpostSvg,
    IconSettingSvg,
    IconUserSvg,
    IconPostSvg,
    IconEditSvg,
    LogoSchoolSvg,
    LogoCareerSvg,
    IconLockSvg,
    IconSimpleuserSvg,
    IconNoticeSvg,
    IconClipSvg,
    IconCalendarSvg,
    IconPaySvg,
    IconReceiptSvg,
    IconHistorySvg,
    SnsTwitterSvg,
    themeTrend,
    themeCreativity,
    themeBusiness,
    themeCareer,
    themeSkill
  },
  data() {
    return {
      burgerMenu: false,
      event_count: 0
    }
  },
  computed: {
    isCareerStatus() {
      return this.$auth.user.career_status === 'completed'
    },
    vookMasterStudioDirectorShow() {
      return beforeIt('2023-11-07 23:59:59')
    },
    vookMasterStudioColoristShow() {
      return beforeIt('2023-11-01 23:59:59')
    }
  },
  mounted() {
    // イベントバッジ用
    this.$axios.$get('/events/entryable_event_count').then((data) => {
      this.event_count = data.count
    })
  },
  methods: {
    convertAssetImage,
    profileImageURL() {
      return (
        this.$auth.user.profile_image.thumb_160.url ||
        require('~/assets/images/user-160.png')
      )
    },
    isClose(value) {
      this.burgerMenu = value
    }
  }
}
</script>
<style lang="scss">
body.header-fixed {
  .l-header {
    position: sticky;
    top: 0;
  }
}
</style>

<style lang="scss" scoped>
$header-inner-height: 39px;
$header-padding: ($header_height - $header-inner-height) / 2; //13px
.l-header {
  position: relative;
  z-index: 10000; // .v-alertより上
  width: 100%;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  @include media(pc) {
    border-bottom: 1px solid $light_violet;
    @include gradation();
  }
  @include media(sp) {
    padding-bottom: 50px; // .header-themeの高さ
    background: #fff;
  }
}
::v-deep {
  //fade
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
    pointer-events: none;
  }

  .notice {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    background: $pink;
    border-radius: 100px;
    color: #fff;
    font-size: 10px;
  }

  // dropdown
  .v-dropdown-menu--active .v-dropdown-menu__trigger {
    z-index: 994;
  }
  .v-dropdown-menu__overlay {
    cursor: pointer;
  }
  .v-dropdown-menu__container {
    max-width: unset !important;
    border: none !important;
  }

  .header-content {
    position: relative;
    z-index: 2; // .header-burger-menuより上(iconのhoverを上に重ねる)
    display: flex;
    align-items: center;
    @include media(pc) {
      max-width: $max_width;
      min-width: $min_width;
      margin: 0 auto;
      height: $header_height;
    }
    @include media(sp) {
      justify-content: space-between;
      height: $header_height_responsive;
    }
  }

  .header-burger {
    @include media(pc) {
      width: $header_height;
      height: $header_height;
      padding: 15px;
    }
    @include media(sp) {
      width: 65px;
      height: $header_height-responsive;
      padding: 10px 15px;
      background: #fff;
    }
    &-button {
      position: relative;
      z-index: 2;
      width: 100%;
      height: 100%;
      cursor: pointer;
      transition: all 0.2s;
      &:hover {
        opacity: 0.5;
      }
      span {
        position: absolute;
        left: 5px;
        transform: translate(0, 0);
        width: calc(100% - 10px);
        height: 1px;
        transition: all 0.4s;
        @include media(pc) {
          background: #fff;
        }
        @include media(sp) {
          background: $violet;
        }
      }
      span:nth-of-type(1) {
        top: 8px;
      }
      span:nth-of-type(2) {
        top: 50%;
        transform: translateY(-50%);
      }
      span:nth-of-type(3) {
        bottom: 8px;
      }
      &.is-opened {
        opacity: 1;
        span {
          width: calc(100% - 6px);
        }
        span:nth-of-type(1) {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(-45deg);
        }
        span:nth-of-type(2) {
          opacity: 0;
        }
        span:nth-of-type(3) {
          bottom: 50%;
          left: 50%;
          transform: translate(-50%, 50%) rotate(45deg);
        }
      }
    }
    &-menu {
      width: 100%;
      overflow-y: scroll;
      background: #fff;
      transition: 0.3s;
      @include media(pc) {
        min-width: $min_width;
        max-height: calc(100vh - #{$header_height});
      }
      @include media(sp) {
        width: 100vw;
        height: calc(100vh - #{$header_height_responsive});
      }
      &-content {
        @include media(pc) {
          display: grid;
          grid-template-columns: 68% 1fr;
          column-gap: $spacer_5;
          padding: $spacer_5;
          &-left {
            grid-column: 1;
            grid-row: 1;
            display: flex;
            flex-direction: column;
          }
          &-right {
            grid-column: 2;
            grid-row: 1;
            display: flex;
            flex-direction: column;
          }
        }
      }
      .campaigns {
        @include media(pc) {
          margin-bottom: $spacer_edge;
        }
      }
      .campaign {
        background: $light_violet;
        display: flex;
        @include media(pc) {
          align-items: center;
          & + .campaign {
            margin-top: 10px;
          }
        }
        @include media(sp) {
          align-items: center;
        }
        &-thumb {
          @include media(pc) {
            width: 228px;
          }
          @include media(sp) {
            width: 150px;
          }
          a {
            display: block;
            &:hover {
              filter: brightness(80%);
            }
          }
          img {
            width: 100%;
          }
        }
        &-txt {
          padding: 20px $spacer_edge;
          flex: 1;
          @include media(sp) {
            padding: 5px;
          }
          &-ttl {
            font-size: 16px;
            line-height: 1.5;
            @include media(pc) {
              margin-bottom: 5px;
            }
            @include media(sp) {
              font-size: 13px;
            }
          }
          p {
            margin-bottom: 10px;
            font-size: 14px;
            line-height: 1.5;
          }
          a {
            text-decoration: none;
            @include media(pc) {
              &:hover {
                opacity: 0.5;
              }
            }
            &.campaign-txt-ttl {
              display: block;
            }
          }
          a:not(.campaign-txt-ttl) {
            padding: 2px 24px 2px 0;
            background: url(~assets/images/svg/arrow_right_b.svg) no-repeat
              right center / 16px auto;
            font-family: Inter;
            font-size: 14px;
            @include media(sp) {
              display: none;
            }
          }
        }
      }
      .campaign + .campaign {
        border-top: solid 1px rgba(255, 255, 255, 0.8);
      }
      .search-wrap {
        @include media(pc) {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: $spacer_edge;
        }
        @include media(sp) {
          padding: $spacer_edge;
        }
      }
      .search {
        @include media(pc) {
          flex: 1;
        }
        @include media(sp) {
          margin-bottom: $spacer_edge;
        }
        .tag-search {
          position: relative;
          margin-bottom: unset;
          input {
            width: 100%;
            line-height: 44px;
            padding: 0 60px 0 15px;
            background: #fff;
            border: 1px solid $light_violet_border;
            border-radius: $radius;
            box-shadow: inset 3px 3px 0 rgba(#000, 0.05);
            font-size: 19px;
            @include media(sp) {
              line-height: 43px;
              padding: 0 44px 0 10px;
              font-size: 16px;
            }
            &:focus {
              border-color: $violet;
              @include placeholder();
            }
          }
          button {
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            padding: 0 15px;
            border: unset;
            background: unset;
            @include media(sp) {
              padding: 0 10px;
            }
            svg {
              fill: none;
              stroke: $violet;
              stroke-width: 2px;
              width: 30px;
              height: 30px;
              @include media(sp) {
                width: 24px;
                height: 24px;
              }
            }
          }
        }
      }
      .guide {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;
        border: 1px solid $violet;
        border-radius: 100px;
        box-shadow: 6px 6px 20px 0px rgba(#2e1993, 0.1),
          -10px -10px 40px 0px rgba(#2e1993, 0.02);
        text-decoration: none;
        font-size: 16px;
        color: $violet;
        @include media(pc) {
          width: calc((100% - #{$spacer_edge} * 2) / 3);
          padding: 8px 0;
          margin-left: $spacer_edge;
        }
        @include media(sp) {
          padding: 8px 0;
        }
        svg {
          fill: none;
          stroke: $violet;
          width: 28px;
          height: 28px;
          margin: 0 6px 0 -6px;
        }
        &:hover {
          transform: translate3d(0, 1px, 0);
          filter: brightness(90%);
          box-shadow: 0;
        }
      }
      .contents {
        @include media(pc) {
          margin-bottom: $spacer_edge;
        }
        ul {
          @include media(pc) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
          }
          @include media(sp) {
            border-top: 1px solid $dark_violet_border;
          }
        }
        li {
          border-bottom: 1px solid $dark_violet_border;
          @include media(pc) {
            width: calc((100% - #{$spacer_edge} * 2) / 3);
            &:nth-child(-n + 3) {
              border-top: 1px solid $dark_violet_border;
            }
          }
          a {
            display: flex;
            align-items: center;
            justify-content: space-between;
            text-decoration: none;
            font-size: 16px;
            @include media(pc) {
              padding: 16px 35px 16px 5px;
              background: url(~assets/images/svg/arrow_right_b.svg) no-repeat
                right 5px center / 20px auto;
            }
            @include media(sp) {
              padding: 20px 54px 20px $spacer_edge;
              background: url(~assets/images/svg/arrow_right_b.svg) no-repeat
                right 24px center / 20px auto;
            }
            &:hover {
              opacity: 0.6;
            }
            .notice {
              width: 19px;
              height: 19px;
              margin: -1px auto -1px 10px;
            }
          }
        }
      }

      .theme {
        ul {
          display: flex;
          @include gradation();
          @include media(sp) {
            flex-wrap: wrap;
            &:after {
              content: '';
              width: calc(100% / 3);
              background: #fff;
            }
          }
          li {
            @include media(pc) {
              flex: 1;
            }
            @include media(sp) {
              width: calc(100% / 3);
              border-right: 1px solid $light_violet_border;
              &:nth-child(-n + 3) {
                border-bottom: 1px solid $light_violet_border;
              }
            }
          }
          li + li {
            @include media(pc) {
              border-left: 1px solid $light_violet_border;
            }
          }
          a {
            display: block;
            text-decoration: none;
            color: #fff;
            @include media(pc) {
              display: flex;
              padding: 12px 10px;
            }
            @include media(sp) {
              padding: 10px 5px 15px;
              text-align: center;
            }
            &:hover {
              background: rgba(#000, 0.2);
            }
            svg {
              display: block;
              margin: 0 auto 10px;
              fill: none;
              stroke: #fff;
              stroke-width: 1px;
              @include media(pc) {
                width: 30px;
                height: 30px;
                margin: 0 6px 0 0;
              }
              @include media(sp) {
                width: 40px;
                height: 40px;
              }
            }
            .txt {
              strong {
                display: block;
                margin-bottom: 5px;
                @include theme_ttl_en;
                @include media(pc) {
                  font-size: 19px;
                  margin-bottom: 2px;
                }
                @include media(sp) {
                  font-size: 13px;
                }
              }
              span {
                display: block;
                font-size: 10px;
              }
            }
          }
        }
      }

      .post {
        background: $light_violet;
        @include media(pc) {
          margin-top: $spacer_edge;
          display: flex;
        }
        &-item {
          flex: 1;
          padding: $spacer_2;
          text-align: center;
          @include media(sp) {
            padding: $spacer_edge;
          }
          &:last-child {
            @include media(pc) {
              border-left: 1px solid $dark_violet_border;
            }
            @include media(sp) {
              border-top: 1px solid $dark_violet_border;
            }
          }
          &-ttl {
            padding-bottom: 10px;
            font-size: 15px;
            span {
              font-weight: bold;
            }
          }
          ul {
            display: flex;
          }
          li {
            flex: 1;
          }
          li + li {
            margin-left: $spacer_2;
          }
          a {
            display: flex;
            align-items: center;
            padding: 8px;
            text-decoration: none;
            border: 1px solid $light_violet_border;
            border-radius: $radius;
            background: #fff;
            box-shadow: 6px 6px 20px 0px rgba(#2e1993, 0.1),
              -10px -10px 40px 0px rgba(#2e1993, 0.02);
            color: $violet;
            @include media(pc) {
              font-size: 16px;
            }
            @include media(sp) {
              font-size: 14px;
            }
            &:hover {
              transform: translate3d(0, 1px, 0);
              filter: brightness(80%);
              box-shadow: 0;
            }
            svg {
              width: 30px;
              height: 30px;
              margin-right: 10px;
              @include media(sp) {
                width: 26px;
                height: 26px;
                margin-right: 7px;
              }
              &.is-fill {
                padding: 2px;
                fill: $violet;
              }
              &.is-stroke {
                stroke: $violet;
                stroke-width: 1.5px;
                fill: none;
              }
            }
          }
        }
      }

      .tool {
        @include media(pc) {
          margin-bottom: $spacer_5;
        }
        &-content {
          @include media(sp) {
            padding: $spacer_edge;
            border-bottom: 1px solid $dark_violet_border;
          }
          &:not(:first-child) {
            @include media(pc) {
              padding-top: $spacer_5;
            }
          }
        }
        &-ttl {
          padding-bottom: $spacer_2;
          @include media(pc) {
            font-size: 17px;
          }
          @include media(sp) {
            font-size: 15px;
          }
        }
        li + li {
          margin-top: $spacer_2;
        }
        &-link {
          display: flex;
          align-items: center;
          box-shadow: 6px 6px 20px 0px rgba(#2e1993, 0.1),
            -10px -10px 40px 0px rgba(#2e1993, 0.02);
          background: #fff;
          text-decoration: none;
          &-thumb {
            flex: 1;
            border: 1px solid $light_violet_border;
          }
          &-txt {
            flex: 1;
            padding: $spacer_1 $spacer_2;
            strong {
              display: block;
              margin-bottom: 8px;
              font-size: 14px;
            }
            svg {
              margin: 2px 0 8px;
            }
            span {
              display: block;
              font-size: 10px;
            }
          }
          &:hover {
            filter: brightness(90%);
          }
        }
      }

      .sns {
        @include media(pc) {
          margin-top: auto;
        }
        @include media(sp) {
          padding: $spacer_edge;
        }
        ul {
          flex: 1;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        // 389px以下のときは、アイコンを5つのみ表示する（LINEアイコンがモバイル時40px以上必須のため、横並びにできる限界が5つのため）
        @media screen and (max-width: 389px) {
          li:nth-child(n + 6) {
            display: none;
          }
        }
        a:hover {
          opacity: 0.6;
        }
        img,
        svg {
          width: 30px;
          height: auto;
          @include media(sp) {
            width: 40px;
          }
        }
        svg {
          fill: #000;
        }
      }
    }
  }

  // ロゴ
  .header-logo {
    a {
      display: flex;
      align-items: center;
      padding: 10px;
    }
    svg {
      @include media(pc) {
        width: 80px;
        height: 28px;
        fill: #fff;
      }
      @include media(sp) {
        width: 60px;
        fill: $font_color_base;
      }
    }
  }

  // テーマ
  .header-theme {
    @include media(sp) {
      position: absolute;
      top: $header_height-responsive;
      left: 0;
      width: 100%;
      padding: 10px 0;
      border-bottom: 1px solid $light_violet;
      overflow-x: scroll;
      @include gradation;
      @include no_scrollbar;
    }
    @include media(pc) {
      padding-left: 56px;
    }
    ul {
      display: flex;
    }
    li + li {
      border-left: 1px solid $light_violet_border;
    }
    a {
      display: flex;
      align-items: center;
      height: 30px;
      padding: 4px $spacer_edge 5px;
      white-space: nowrap;
      text-decoration: none;
      color: #fff;
      @include media(pc) {
        font-size: 16px;
      }
      @include media(sp) {
        font-size: 12px;
      }
    }
  }

  // アイコン
  .header-icon {
    display: flex;
    @include media(pc) {
      margin-left: auto;
    }
    & > li {
      .v-dropdown-menu--active {
        .header-icon-button {
          opacity: 1 !important;
          @include media(sp) {
            background: #fff;
          }
        }
      }
      .header-icon-button {
        display: flex;
        text-decoration: none;
        cursor: pointer;
        transition: all 0.2s;
        @include media(pc) {
          width: 86px;
          height: $header_height;
        }
        @include media(sp) {
          width: 65px;
          height: $header_height-responsive;
        }
        &:hover {
          @include media(pc) {
            opacity: 0.65;
          }
        }
        &-inner {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          svg {
            fill: none;
            stroke-width: 1px;
            @include media(pc) {
              width: 35px;
              height: 35px;
              margin-bottom: 3px;
              stroke: #fff;
            }
            @include media(sp) {
              width: 31px;
              height: 31px;
              margin-bottom: 1px;
              stroke: $violet;
            }
          }
          &-img {
            position: relative;
            @include media(pc) {
              width: 34px;
              height: 34px;
              margin-bottom: 4px;
            }
            @include media(sp) {
              width: 30px;
              height: 30px;
              margin-bottom: 3px;
            }
            .notice {
              position: absolute;
              top: -6px;
              right: -8px;
              width: 18px;
              height: 18px;
              font-size: 10px;
              @include media(sp) {
                top: -4px;
                right: -11px;
              }
            }
            img {
              border-radius: 50%;
              @include media(pc) {
                border: 1px solid rgba(#fff, 0.7);
              }
            }
          }
          &-txt {
            white-space: nowrap;
            font-size: 10px;
            color: $violet;
            @include media(pc) {
              color: #fff;
            }
          }
        }
        &-post {
          svg {
            margin-bottom: 4px;
            width: 36px;
          }
        }
      }
      //投稿する
      .header-post {
        background-color: #f1effb;
        .ttl {
          text-align: center;
          margin-bottom: 10px;
        }
        .header-post-article {
          padding: 15px;
          border-bottom: 1px solid #c2c0d3;
        }
        .header-post-portfolio {
          padding: 15px;
        }
        ul {
          li {
            &:first-child {
              margin-bottom: 10px;
            }
          }
        }
        a {
          display: flex;
          align-items: center;
          padding: 8px;
          text-decoration: none;
          border: 1px solid $light_violet_border;
          border-radius: $radius;
          background: #fff;
          box-shadow: 6px 6px 20px 0px rgba(#2e1993, 0.1),
            -10px -10px 40px 0px rgba(#2e1993, 0.02);
          color: $violet;
          font-size: 16px;
          &:hover {
            transform: translate3d(0, 1px, 0);
            filter: brightness(80%);
            box-shadow: 0;
          }
          svg {
            width: 30px;
            height: 30px;
            margin-right: 10px;
            &.is-fill {
              padding: 2px;
              fill: $violet;
            }
            &.is-stroke {
              stroke: $violet;
              stroke-width: 1.5px;
              fill: none;
            }
          }
        }
      }
      .header-icon-menu {
        width: 290px;
        padding: $spacer_edge;
        background: #fff;
        overflow-y: scroll;
        @include media(pc) {
          max-height: calc(100vh - #{$header_height});
        }
        @include media(sp) {
          max-height: calc(100vh - #{$header_height-responsive});
        }
        a {
          text-decoration: none;
        }
        // マイページ
        .profile {
          padding-bottom: $spacer_2;
          &-name {
            display: flex;
            align-items: center;
            margin-bottom: 8px;
            .name {
              margin-right: 5px;
              font-weight: bold;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .status {
              padding: 3px 5px;
              border-radius: $radius;
              white-space: nowrap;
              font-size: 10px;
              @include premium_gradation();
            }
          }
          &-link {
            display: flex;
            a {
              color: $violet;
              font-size: 12px;
              & + a {
                margin-left: 5px;
                padding-left: 5px;
                border-left: solid 1px #ccc;
              }
            }
          }
        }
        .menu {
          border-top: 1px solid $light_violet_border;
          ul {
            padding: $spacer_1 0;
            border-bottom: 1px solid $light_violet_border;
          }
          li {
            a {
              display: flex;
              align-items: center;
              padding: 5px 0;
              font-size: 13px;
              color: $violet;
              font-weight: bold;
              &:hover {
                opacity: 0.7;
              }
              .notice {
                width: 19px;
                height: 19px;
                margin-left: 10px;
              }
            }
            svg {
              display: block;
              width: 30px;
              height: 30px;
              margin-right: 5px;
              fill: none;
              stroke-width: 1px;
              stroke: $violet;
            }
          }
        }
        .service {
          padding: $spacer_2 0;
          ul {
            li {
              .mypage-btn {
                display: block;
                padding: 15px;
                text-align: center;
                border-radius: $radius;
                font-weight: bold;
                color: #fff;
                @include btn_hover();
                &:hover {
                  filter: brightness(80%);
                }
                &.is-school {
                  background: $school_primary;
                }
                &.is-career {
                  @include career_gradation();
                }
              }
            }
            li + li {
              padding-top: $spacer_1;
            }
          }
        }
        .logout {
          a {
            display: inline-block;
            padding: 15px 20px;
            border-radius: $radius;
            background: #eee;
            font-size: 12px;
            &:hover {
              background: #ddd;
            }
          }
        }

        // 未ログイン登録導線
        .login {
          text-align: center;
          .ttl {
            margin-bottom: 10px;
            line-height: 1.4;
            font-size: 15px;
          }
          .btn {
            a {
              display: flex;
              flex-direction: column;
              justify-content: center;
              padding: 14px 0;
              border-radius: 8px;
              box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.16);
              text-decoration: none;
              font-size: 17px;
              &:hover {
                transform: translate3d(0, 1px, 0);
                filter: brightness(80%);
                box-shadow: 0;
              }
              span {
                display: block;
                margin-top: 8px;
                font-size: 12px;
              }
            }
          }
          &-member {
            padding-bottom: 20px;
            border-bottom: solid 1px $light_violet_border;
            .btn {
              a {
                background: #fff;
                border: solid 1px $violet;
                color: $violet;
              }
            }
          }
          &-guset {
            padding-top: 20px;
            .btn {
              margin-bottom: 10px;
              a {
                color: #fff;
                &.theme-premium {
                  @include premium_gradation();
                }
                &.theme-vook {
                  @include gradation();
                }
              }
            }
            p {
              a {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }
  .header-premium {
    display: flex;
    align-items: center;
    margin-left: auto;
    padding: 0 17px 0 10px;
    @include media(sp) {
      display: none;
    }
    a {
      display: inline-block;
      padding: 13px 15px;
      border-radius: 100px;
      font-size: 14px;
      text-decoration: none;
      white-space: nowrap;
      font-weight: 500;
      border: 1px solid rgba(#fff, 0.7);
      @include premium_gradation();
      &:hover {
        @include media(pc) {
          opacity: 0.65;
        }
      }
    }
    & + .header-icon {
      @include media(pc) {
        margin-left: unset;
      }
    }
  }
}
</style>
